<div class="col-12">
  <nb-card [nbSpinner]="isLoading">
    <nb-card-header style="border: 0;">
      {{ isFirstAccess ? 'Este é seu primeiro acesso, crie uma nova' : 'Alterar' }} senha
    </nb-card-header>

    <nb-card-body nbspinner class="d-flex flex-column align-items-center col-12">
      <form [formGroup]="formGroup">
        <div class="w-100 d-flex flex-wrap">
          <div class="w-100 col-12 col-md-6">

            <label class="ml-3">Senha {{isFirstAccess ? 'provisória' : 'atual'}}</label>

            <nb-form-field class="col-12">
              <input class="input" [type]="shouldHideCurrentPassword ? 'password' : 'text'" nbInput fullWidth
                formControlName="currentPassword" fullWidth nbInput shape="rectangle" maxlength="32">
              <button type="button" nbSuffix nbButton ghost
                (click)="shouldHideCurrentPassword = !shouldHideCurrentPassword">
                <nb-icon [icon]="!shouldHideCurrentPassword ? 'eye-outline' : 'eye-off-outline'" pack="eva">
                </nb-icon>
              </button>
            </nb-form-field>

            <label class="mt-2 ml-3">Nova senha</label>

            <nb-form-field class="col-12">
              <input class="input" formControlName="newPassword" (ngModelChange)="validateNewPassword()" fullWidth
                nbInput shape="rectangle" [type]="shouldHideNewPassword ? 'password' : 'text'" nbInput fullWidth maxlength="32">
              <button type="button" nbSuffix nbButton ghost (click)="shouldHideNewPassword = !shouldHideNewPassword">
                <nb-icon [icon]="!shouldHideNewPassword ? 'eye-outline' : 'eye-off-outline'" pack="eva">
                </nb-icon>
              </button>
            </nb-form-field>

            <label class="mt-2 ml-3">Confirmar senha</label>

            <nb-form-field class="col-12">
              <input class="input" formControlName="confirmPassword" (ngModelChange)="validateNewPassword()" fullWidth
                nbInput shape="rectangle" [type]="shouldHideConfirmPassword ? 'password' : 'text'" nbInput fullWidth maxlength="32">
              <button type="button" nbSuffix nbButton ghost
                (click)="shouldHideConfirmPassword = !shouldHideConfirmPassword">
                <nb-icon [icon]="!shouldHideConfirmPassword ? 'eye-outline' : 'eye-off-outline'" pack="eva">
                </nb-icon>
              </button>
            </nb-form-field>
          </div>

          <div class="col-12 col-md-6 d-flex flex-column">
            <label>Sua senha deve possuir</label>

            <div class="d-flex span" *ngIf="passwordValidators?.minLength">
              <nb-icon
                class="regex-icon"
                [icon]="passwordValidationStatus?.validations?.hasMinLength ? 'checkmark-outline' : 'close-outline'">
              </nb-icon>
              Pelo menos {{ passwordValidators?.minLength }} caracteres
            </div>

            <div class="d-flex span" *ngIf="passwordValidators?.hasNumber">
              <nb-icon
                class="regex-icon"
                [icon]="passwordValidationStatus?.validations?.hasNumber ? 'checkmark-outline' : 'close-outline'">
              </nb-icon>
              Pelo menos 1 número
            </div>

            <div class="d-flex span" *ngIf="passwordValidators?.hasLetter">
              <nb-icon
                class="regex-icon"
                [icon]="passwordValidationStatus?.validations?.hasLetter ? 'checkmark-outline' : 'close-outline'">
              </nb-icon>
              Pelo menos 1 letra
            </div>

            <div class="d-flex span" *ngIf="passwordValidators?.hasUppercaseLetter">
              <nb-icon
                class="regex-icon"
                [icon]="passwordValidationStatus?.validations?.hasUppercaseLetter ? 'checkmark-outline' : 'close-outline'">
              </nb-icon>
              Pelo menos 1 letra maiúscula
            </div>

            <div class="d-flex span" *ngIf="passwordValidators?.hasLowercaseLetter">
              <nb-icon
                class="regex-icon"
                [icon]="passwordValidationStatus?.validations?.hasLowercaseLetter ? 'checkmark-outline' : 'close-outline'">
              </nb-icon>
              Pelo menos 1 letra minúscula
            </div>

            <div class="d-flex span" *ngIf="passwordValidators?.hasSpecialCharacter">
              <nb-icon
                class="regex-icon"
                [icon]="passwordValidationStatus?.validations?.hasSpecialCharacter ? 'checkmark-outline' : 'close-outline'">
              </nb-icon>
              Pelo menos 1 caractere especial
            </div>

            <div class="d-flex span">
              <nb-icon class="regex-icon" [icon]="equalsPassword ? 'checkmark-outline' : 'close-outline'">
              </nb-icon>
              As senhas devem ser iguais
            </div>

            <div class="mt-3 ml-5">
              <button [disabled]="!passwordValidationStatus?.isOk" nbButton shape="round" size="small"
                class="btn-create btn-save ml-2 col-8" (click)="resetPassword()">
                {{ isFirstAccess? 'Salvar Nova senha' : 'Confirmar edições' }}
              </button>
              <button *ngIf="!isFirstAccess" nbButton shape="round" size="small" outline
                      class="mt-2 ml-2 btn-default col-8" (click)="close()">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
</div>
