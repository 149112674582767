//#region Imports

import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

//#endregion

@Component({
  selector: 'ngx-confirm-logout',
  templateUrl: './confirm-logout.component.html',
  styleUrls: ['./confirm-logout.component.scss'],
})
export class ConfirmLogoutComponent {

  //#region Constructor

  constructor(
    private readonly ref: NbDialogRef<ConfirmLogoutComponent>,
  ) { }

  //#endregion

  //#region Public Methods

  public onSubmit() {
    this.ref.close(true);
  }

  public onCancel() {
    this.ref.close();
  }

  //#endregion

}
