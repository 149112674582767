<nb-card>
  <nb-card-header>
    <span class="span">
      Tem certeza que <br>
      deseja sair?
    </span>
  </nb-card-header>
  <nb-card-body>

    <div class="btns-modal">
      <button class="btn-close" (click)="onCancel()">Cancelar</button>
      <button class="btn-leave" (click)="onSubmit()">Sair</button>
    </div>

  </nb-card-body>
</nb-card>
