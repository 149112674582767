//#region Imports

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { ConfirmLogoutComponent } from '../../modals/confirm-logout/confirm-logout.component';
import { UserService } from '../../services/user/user.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AuthenticateGuard implements CanActivate {

  //#region Constructor

  constructor(
    protected readonly router: Router,
    protected readonly user: UserService,
    protected readonly dialogService: NbDialogService,
  ) { }

  //#endregion

  //#region Protected Methods

  public async canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Promise<boolean> {
    const shouldLogout = route.queryParamMap.get('shouldLogout');
    const { unprotectedRoute, protectedRoute, routeToRedirect } = route.data || {};

    if (shouldLogout) {
      this.openConfirmLogout();
      return false;
    }

    if (!routeToRedirect)
      return true;

    const hasToken = !!localStorage.getItem(environment.keys.token);

    if (hasToken && protectedRoute)
      return true;

    if (!hasToken && unprotectedRoute)
      return true;

    return await this.router.navigate([routeToRedirect], { replaceUrl: true }).then(() => false);
  }

  public openConfirmLogout(): void {
    const dialogRef = this.dialogService.open(ConfirmLogoutComponent);

    dialogRef.onClose.subscribe(async (logout): Promise<void> => {
      if (!logout)
        return;

      localStorage.clear();
      this.user.refreshCurrentUser();
      return void await this.router.navigate([environment.config.redirectToWhenUnauthenticated]);
    });
  }

  //#endregion

}
