export const routes = {
  auth: {
    login: '/auth/local',
  },
  missions: {
    getQRCode: '/missions/generate/qrcode/{missionId}',
    get: '/missions',
    fromCampaign: '/missions/campaign/{campaignId}',
    getOne: '/missions/{missionId}',
    create: '/missions',
    update: '/missions/{missionId}',
    delete: '/missions/{missionId}',
  },
  missionBlock: {
    get: '/missions/blocks',
    reorder: '/missions/blocks/reorder',
    getMission: '/missions/blocks/mission/{missionId}',
    create: '/missions/blocks',
    getOne: '/missions/blocks/{missionBlockId}',
    update: '/missions/blocks/{missionBlockId}',
    delete: '/missions/blocks/{missionBlockId}',
  },
  questions: {
    get: '/questions',
    getOne: '/questions/{questionId}',
    create: '/questions',
    update: '/questions/{questionId}',
    delete: '/questions/{questionId}',
  },
  products: {
    base: '/products',
    getOne: '/products/{productId}',
    put: '/products/{productId}',
    update: '/products/{productId}',
    create: '/products',
    toggle: '/products/toggle-active/{productId}',
  },
  company: {
    list: '/companies',
    get: '/companies/{companyId}',
    create: '/companies',
    update: '/companies/{companyId}',
    me: '/companies/me',
  },
  users: {
    list: '/users',
    get: '/users/{userId}',
    create: '/users',
    update: '/users/{userId}',
    me: '/users/me',
    byCampaign: '/users/users/by/campaign/{campaignId}',
    listWithAreas: '/users/with-areas',
  },
  areas: {
    list: '/areas',
    listWithProductsAndUsers: '/areas/with-products-and-users',
    get: '/areas/{areaId}',
    create: '/areas',
    update: '/areas/{areaId}',
    delete: '/areas/{areaId}',
    areasHaveExchanges: '/areas/have-exchanges',
  },
  campaigns: {
    list: '/campaigns',
    get: '/campaigns/{campaignId}',
    create: '/campaigns',
    update: '/campaigns/{campaignId}',
    delete: '/campaigns/{campaignId}',
    notification: '/campaigns/notification/{campaignId}',
    userHavePoints: '/campaigns/user-have-points/{userId}',
    thatHaveCompletedMissions: '/campaigns/that-have-completed-missions',
    toggle: '/campaigns/toggle/{campaignId}',
  },
  stickers: {
    list: '/sticker',
    get: '/sticker/{stickerId}',
    create: '/sticker',
    update: '/sticker/{stickerId}',
    getQRCode: '/sticker/generate/qrcode/{stickerId}',
  },
  notifications: {
    list: '/notifications/batches',
    get: '/notifications/batches/{notificationId}',
    create: '/notifications/batches',
    update: '/notifications/batches/{notificationId}',
    delete: '/notifications/batches/{notificationId}',
  },
  usersWithAccess: {
    list: '/campaign-users-with-access',
    byCampaign: (campaignId: string) => `/campaign-users-with-access/campaign/${campaignId}`,
    delete: (userId: string) => `/campaign-users-with-access/${userId}`,
  },
  userMissions: {
    list: '/users-missions',
  },
  userPassword: {
    forgotPassword: '/users/password/forgot/email/{email}',
    resetPassword: '/users/password/reset/{resetPasswordCode}',
    reSendCode: '/users/password/resend/email/{email}',
    verifyCode: '/users/password/verify/{resetPasswordCode}',
    changePassword: '/users/password/change',
    checkPassword: '/users/check-password',
  },
  receipts: {
    list: '/receipts',
    finish: '/receipts/finish/{id}',
  },
  survey: {
    list: '/survey-questions',
    delete: '/survey-questions/{questionId}',
    create: '/survey-questions',
    update: '/survey-questions/{questionId}',
    getOne: '/survey-questions/{questionId}',
  },
  settings: {
    password: '/api-settings',
  },
};
