export async function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getCrudErrors({ status, error }: any): string[] {
  if (!error || status >= 500 && status <= 599)
    return ['Ocorreu um erro interno, por favor, tente novamente.'];

  if (!Array.isArray(error.message)) {
    if (typeof error.message === 'string' && error.message.includes('Cannot'))
      return ['A rota especificada não foi encontrada, por favor, contate os administradores se o erro persistir.'];

    return [error.message || 'Ocorreu um erro inesperado, por favor, contate os administradores se o erro persistir.'];
  }

  if (error.message.every((message: any) => typeof message === 'string'))
    return error.message;

  // @ts-ignore
  return error.message.map(({ constraints }) => constraints && Object.values(constraints) || [])
    .reduce((acc: any, actual: any) => [...acc, ...actual] as string[]);
}

export function isString(value: any): boolean {
  return Object.prototype.toString.call(value) === '[object String]';
}

export function verifyYoutubeUrl(url: string): RegExp {
  if (url.includes('watch'))
    return new RegExp(/(?:https?:\/\/)?(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:watch|v|embed)(?:\.php)?(?:\?.*v=|\/))([a-zA-Z0-9_-]+)/);

  return /\/shorts\/([A-Za-z0-9_-]+)/;
}

export function extractVimeoId(url: string): string | null {
  const regex = /(?:vimeo\.com\/|player\.vimeo\.com\/video\/)(.+)/;
  const match = url.match(regex);
  return match ? match[1] : null;
}

export function isValid<T>(value: T): value is NonNullable<T> {
  return !(value === null || value === undefined);
}

export function isValidCpf(value: string): boolean {
  const BLACKLIST: Array<string> = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
    '12345678909',
  ];

  const STRICT_STRIP_REGEX = /[.-]/g;
  const LOOSE_STRIP_REGEX = /[^\d]/g;

  const verifierDigit = (digits: string): number => {
    const numbers: Array<number> = digits
      .split('')
      .map(num => parseInt(num, 10));

    const modulus = numbers.length + 1;
    const multiplied: Array<number> = numbers.map((num, index) => num * (modulus - index));
    const mod: number = multiplied.reduce((buffer, num) => buffer + num) % 11;

    return (mod < 2 ? 0 : 11 - mod);
  };

  const strip = (num: string, strict?: boolean): string => {
    const regex: RegExp = strict ? STRICT_STRIP_REGEX : LOOSE_STRIP_REGEX;
    return (num || '').replace(regex, '');
  };

  const valid = (num: string, strict?: boolean): boolean => {
    const stripped: string = strip(num, strict);

    // CPF must be defined
    if (!stripped)
      return false;


    // CPF must have 11 chars
    if (stripped.length !== 11)
      return false;


    // CPF can't be blacklisted
    if (BLACKLIST.includes(stripped))
      return false;


    let numbers: string = stripped.substr(0, 9);
    numbers += verifierDigit(numbers);
    numbers += verifierDigit(numbers);

    return numbers.substr(-2) === stripped.substr(-2);
  };

  return valid(value);
}

export function transformName(value: string): string {
  return value.replace(/(^|\s)[\p{L}]/gu, (match) => match.toUpperCase());
}
