//#region Imports

import { Injectable } from '@angular/core';
import { ValidatePasswordInterface, ValidationsChecks } from '../../models/interfaces/validate-password.interface';
import { PasswordValidationProxy } from '../../models/proxys/password-validation.proxy';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class PasswordValidationService {

  //#region Public Methods

  public validatePassword(password: string, confirmPassword: string, validations?: PasswordValidationProxy): ValidatePasswordInterface {
    let passwordValidations: ValidationsChecks = {};

    if (!validations) {
      return {
        validations: passwordValidations,
        isOk: password !== confirmPassword,
      };
    }

    if (validations?.minLength) {
      passwordValidations = {
        ...passwordValidations,
        hasMinLength: password.length >= validations.minLength,
      };
    }

    if (validations?.hasLetter) {
      passwordValidations = {
        ...passwordValidations,
        hasLetter: /[a-zA-Z]/.test(password),
      };
    }

    if (validations?.hasUppercaseLetter) {
      passwordValidations = {
        ...passwordValidations,
        hasUppercaseLetter: /[A-Z]/.test(password),
      };
    }

    if (validations?.hasLowercaseLetter) {
      passwordValidations = {
        ...passwordValidations,
        hasLowercaseLetter: /[a-z]/.test(password),
      };
    }

    if (validations?.hasNumber) {
      passwordValidations = {
        ...passwordValidations,
        hasNumber: /[0-9]/.test(password),
      };
    }

    if (validations?.hasSpecialCharacter) {
      passwordValidations = {
        ...passwordValidations,
        hasSpecialCharacter: /[^a-zA-Z0-9\s]/.test(password),
      };
    }

    const isAllAgreed = password === confirmPassword &&
      (passwordValidations?.hasMinLength || !validations?.minLength) &&
      (passwordValidations?.hasLetter || !validations?.hasLetter) &&
      (passwordValidations?.hasUppercaseLetter || !validations?.hasUppercaseLetter) &&
      (passwordValidations?.hasLowercaseLetter || !validations?.hasLowercaseLetter) &&
      (passwordValidations?.hasNumber || !validations?.hasNumber) &&
      (passwordValidations?.hasSpecialCharacter || !validations.hasSpecialCharacter);

    return {
      validations: passwordValidations,
      isOk: isAllAgreed,
    };
  }

  //#endregion

}
