import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
// eslint-disable-next-line import/no-unresolved
import ptBr from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbRoleProvider, NbSecurityModule } from '@nebular/security';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbTimepickerModule,
  NbToastrModule,
} from '@nebular/theme';
import { MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/tooltip';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { ptBR } from 'date-fns/locale';
import { environment } from '../environments/environment';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CheckPasswordModule } from './modals/check-password/check-password.module';
import { ConfirmLogoutModule } from './modals/confirm-logout/confirm-logout.module';
import { EditPasswordModule } from './modals/edit-password/edit-password.module';
import { HttpAsyncModule } from './modules/http-async/http-async.module';
import { BaseUrlInterceptor } from './modules/http-async/interceptors/base-url.interceptor';
import { BearerTokenInterceptor } from './modules/http-async/interceptors/bearer-token.interceptor';
import { HttpAsyncHeadersInterceptor } from './modules/http-async/interceptors/http-async-headers.interceptor';
import { RefreshTokenInterceptor } from './modules/http-async/interceptors/refresh-token.interceptor';
import { RetryInterceptor } from './modules/http-async/interceptors/retry.interceptor';
import { IsOfflineInterceptor } from './modules/network/interceptors/is-offline.interceptor';
import { NetworkModule } from './modules/network/network.module';
import { RoleService } from './services/role/role.service';
import { Paginator } from './shared/pagination/paginatorLabels.shared';

registerLocaleData(ptBr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    EditPasswordModule,
    ConfirmLogoutModule,
    CheckPasswordModule,
    NbDialogModule.forRoot(),
    NbToastrModule.forRoot({
      duration: 5000,
      destroyByClick: true,
      limit: 3,
    }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    NbDateFnsDateModule.forRoot({
      format: 'dd/MM/yyyy',
      parseOptions: { locale: ptBR },
      formatOptions: { locale: ptBR },
    }),
    HttpAsyncModule.withConfig({
      baseUrl: environment.api.baseUrl,
      defaultHeaders: {
        ['Accept']: 'application/json',
        ['Content-Type']: 'application/json',
      },
      bearerTokenKey: environment.keys.token,
    }),
    NbSecurityModule.forRoot({
      accessControl: {
        none: {
          view: ['dashboard'],
          update: [],
          create: [],
          remove: [],
        },
        user: {
          view: [
            'dashboard',
            'users',
            'profile',
            'campaigns',
            'missions',
            'areas',
            'products',
          ],
          update: [],
          create: [],
          remove: [],
        },
        company: {
          view: '*',
          create: '*',
          remove: '*',
          update: '*',
        },
        seller: {
          view: [
            'profile',
            'areas',
            'products',
            'missions',
            'receipts',
            'campaigns',
            'receiptsAreaFilter',
          ],
          create: [
            'products',
            'missions',
            'campaigns',
            'missions',
            'questions',
          ],
          remove: ['products', 'missions', 'questions'],
          update: [
            'products',
            'missions',
            'profileSeller',
            'receipts',
            'campaigns',
            'questions',
          ],
        },
      },
    }),
    NetworkModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: HTTP_INTERCEPTORS, useClass: IsOfflineInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAsyncHeadersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    { provide: NbRoleProvider, useClass: RoleService },
    { provide: MatPaginatorIntl, useClass: Paginator },
    MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
