//#region Imports

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { ValidatePasswordInterface } from '../../models/interfaces/validate-password.interface';
import { ResetPasswordPayload } from '../../models/payloads/reset-password.payload';
import { PasswordValidationProxy } from '../../models/proxys/password-validation.proxy';
import { UserProxy } from '../../models/proxys/user.proxy';
import { HttpAsyncService } from '../../modules/http-async/services/http-async.service';
import { PasswordValidationService } from '../../services/password-validation/password-validation.service';
import { UserService } from '../../services/user/user.service';
import { getCrudErrors } from '../../shared/utils/functions';

//#endregion

@Component({
  selector: 'ngx-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss'],
})
export class EditPasswordComponent implements OnInit {

  //#region Constructor

  constructor(
    protected readonly formBuilder: FormBuilder,
    protected readonly router: Router,
    protected readonly http: HttpAsyncService,
    protected readonly toast: NbToastrService,
    protected readonly userService: UserService,
    private readonly passwordValidationService: PasswordValidationService,
    protected dialogRef: NbDialogRef<EditPasswordComponent>,
  ) {
    this.formGroup = this.formBuilder.group(
      {
        newPassword: ['', Validators.required],
        confirmPassword: ['', Validators.required],
        currentPassword: ['', Validators.required],
      },
    );
  }

  //#endregion

  //#region Public Properties

  @Input()
  public user?: UserProxy;

  @Input()
  public isFirstAccess?: boolean;

  public isLoading: boolean = false;

  public formGroup: FormGroup;

  public equalsPassword?: boolean = false;

  public shouldHideCurrentPassword = true;

  public shouldHideNewPassword = true;

  public shouldHideConfirmPassword = true;

  public passwordValidationStatus?: ValidatePasswordInterface = {
    validations: {},
    isOk: false,
  };

  public passwordValidators?: PasswordValidationProxy;

  //#endregion

  //#region LifeCycle Events

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;

    this.isFirstAccess = this.userService.getCurrentUser()?.shouldRequestPasswordChange;
    await this.loadPasswordValidation();

    this.isLoading = false;
  }

  //#endregion

  //#region Public Methods

  public close(): void {
    this.dialogRef.close();
  }

  public async resetPassword(): Promise<void> {
    if (this.isLoading) return;

    this.isLoading = true;

    const payload: ResetPasswordPayload = this.formGroup.getRawValue();

    const url = environment.api.userPassword.changePassword;

    const { error } = await this.http.post<UserProxy>(url, payload);

    this.isLoading = false;

    if (error)
      return void this.toast.danger(getCrudErrors(error)[0], 'Oops...');

    this.toast.success('Senha alterada!', 'Sucesso');

    await this.close();
  }

  public validateNewPassword(): void {
    const payload = this.formGroup.getRawValue();

    this.equalsPassword = payload.newPassword === payload.confirmPassword;
    this.passwordValidationStatus = this.passwordValidationService.validatePassword(
      payload.newPassword,
      payload.confirmPassword,
      this.passwordValidators,
    );
  }

  public async loadPasswordValidation(): Promise<void> {
    const url = environment.api.settings.password;
    const { error, success } = await this.http.get<{ passwordValidations: PasswordValidationProxy }>(url);

    if (error)
      this.toast.danger('Houve um erro ao carregar os requisitos da senha', 'Oops...');

    this.passwordValidators = success?.passwordValidations;
  }

  //#endregion

}
