//#region Imports

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NbToastrService } from '@nebular/theme';
import { HttpAsyncService } from '../../modules/http-async/services/http-async.service';
import { ApiSettingsProxy } from '../../models/proxys/api-settings.proxy';
import { environment } from '../../../environments/environment';
import { getCrudErrors } from '../../shared/utils/functions';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class ApiSettingsService {

  constructor(
    protected readonly toast: NbToastrService,
    protected readonly http: HttpAsyncService,
  ) {}

  //#region Private Property

  private apiSettings: BehaviorSubject<ApiSettingsProxy | null> = new BehaviorSubject<ApiSettingsProxy | null>(null);

  //#endregion

  //#region Public Property

  public async getApiSettings(): Promise<any> {
    if (this.apiSettings.value)
      return this.apiSettings.value;

    try {
      const { success } = await this.http.get<ApiSettingsProxy>(environment.api.settings.get);

      if (success) {
        this.apiSettings.next(success);
        localStorage.setItem(environment.keys.pages, JSON.stringify(success));
        return this.apiSettings.value;
      }
    } catch (err) {
      this.toast.danger(getCrudErrors(err)[0], 'Oops...');
    }
  }

  public getApiSettingsValue(): ApiSettingsProxy | null {
    return this.apiSettings.value;
  }

  public getApiSettings$(): Observable<ApiSettingsProxy | null> {
    return this.apiSettings.asObservable();
  }

  //#endregion
}
