import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class Paginator extends MatPaginatorIntl {
  public constructor() {
    super();

    this.itemsPerPageLabel = 'Itens por página';
    this.firstPageLabel = 'Primeira página';
    this.lastPageLabel = 'Última página';
    this.nextPageLabel = 'Próxima página';
    this.previousPageLabel = 'Página anterior';

    this.changes.next();
  }

  public getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0)
      return `0 de ${ length }`;

    length = Math.max(length, 0);

    const startIndex: number = page * pageSize;
    const endIndex: number = startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

    return `${ startIndex + 1 } - ${ endIndex } de ${ length }`;
  };

}
