//#region Imports

import { Component, OnInit } from '@angular/core';
import { NbIconLibraries } from '@nebular/theme';
import { SeoService } from './@core/utils';
import { ApiSettingsProxy } from './models/proxys/api-settings.proxy';
import { ApiSettingsService } from './services/api-settings/api-settings.service';

//#endregion

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  //#region Constructor

  constructor(
    private seoService: SeoService,
    private iconLibraries: NbIconLibraries,
    private readonly apiSettingsService: ApiSettingsService,
  ) {
    this.iconLibraries.registerFontPack('my-icons', { iconClassPrefix: 'cv' });
  }

  //#endregion

  //#region LifeCycle Events

  public async ngOnInit(): Promise<void> {
    this.seoService.trackCanonicalChanges();

    this.getApiSettings();
  }

  //#endregion

  //#region Private Methods

  private async getApiSettings(): Promise<void> {
    const apiSettings: ApiSettingsProxy = await this.apiSettingsService.getApiSettings();

    if (!apiSettings)
      await this.apiSettingsService.getApiSettings();
  }

  //#endregion

}
