//#region Imports

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { HttpAsyncConfig } from '../models/http-async.config';
import { HTTP_ASYNC_CONFIG } from '../models/injection-tokens';

//#endregion

@Injectable()
export class BearerTokenInterceptor implements HttpInterceptor {
  //#region Constructor

  constructor(
    protected readonly router: Router,
    protected readonly toast: NbToastrService,
    @Inject(HTTP_ASYNC_CONFIG)
    @Optional()
    protected readonly config?: HttpAsyncConfig,
  ) {}

  //#endregion

  //#region Public Static Properties

  public static readonly DISABLE_HEADER: string = 'X-Disabled-BearerToken';

  //#endregion

  //#region Public Methods

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (req.headers.get(BearerTokenInterceptor.DISABLE_HEADER)) {
      return next.handle(
        req.clone({
          headers: req.headers.delete(BearerTokenInterceptor.DISABLE_HEADER),
        }),
      );
    }

    if (!this.config?.bearerTokenKey) {
      console.warn(
        'Você incluiu o Interceptor para adicionar o Bearer Token a requisição ' +
          'mas não configurou a chave para buscar o valor do cache no módulo.',
      );

      return next.handle(req);
    }

    const token = localStorage.getItem(this.config.bearerTokenKey);

    if (!token)
      return next.handle(req);

    const headers = req.headers.set('Authorization', token);

    return next.handle(req.clone({ headers }));
  }

  //#endregion
}
